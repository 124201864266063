




















import { Component, Mixins } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import { centro_trabajo } from "@/shared/dtos/centro_trabajo";
import { datatypes } from "@/components/DataTableGenerico/datatypes";
import centro_trabajoModule from "@/store/modules/centro_trabajo-module";
import datos_empresaModule from "@/store/modules/datos_empresa-module";

@Component({
  components: {},
})
export default class centro_trabajoFormulario extends Mixins(
  HelperDinamicallyForm
) {
  public item_pantalla: centro_trabajo = new centro_trabajo();

  public async OnCreated() {
    this.ShowLoading();

    if (this.getparamId().HayParametro) {
      await centro_trabajoModule.getcentro_trabajo(this.getparamId().Parametro);
      this.item_pantalla = centro_trabajoModule.centro_trabajo;
    }
    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
    await datos_empresaModule.getdatos_empresas();
    this.HideLoading();
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    this.AddFormItemDataDefaultString(
      this.item_pantalla.nombre,
      "Nombre",
      "nombre",
      60
    )
      .isRequired()
      .Dimesiones(12, 10, 6);

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_datos_empresa,
      "Empresa",
      "id_datos_empresa"
    )
      .isRequired()
      .Dimesiones(12, 10, 6)
      .CreateComboSingle(datos_empresaModule.datos_empresas, "nombre", "id");
    this.AddFormItemDataDefaultString(
      this.item_pantalla.notas,
      "Notas",
      "notas",
      datatypes.maxlength
    )
      .Dimesiones(12, 10, 6)
      .CreateTextArea();
  }

  public Insertar(object: centro_trabajo) {
    centro_trabajoModule.guardarcentro_trabajo(object).then(() => {
      this.AllSaveOk();
    });
  }

  public Actualizar(object: centro_trabajo) {
    centro_trabajoModule.modificarcentro_trabajo(object).then(() => {
      this.AllSaveOk(); //guardo correctamente y me cierro
    });
  }
}
